/* merriweather-sans-regular - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/merriweather-sans-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/merriweather-sans-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/merriweather-sans-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/merriweather-sans-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/merriweather-sans-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/merriweather-sans-v21-latin-regular.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-700 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/merriweather-sans-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/merriweather-sans-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/merriweather-sans-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/merriweather-sans-v21-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/merriweather-sans-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/merriweather-sans-v21-latin-700.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}

/* arimo-regular - latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/arimo-v26-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/arimo-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/arimo-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/arimo-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/arimo-v26-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/arimo-v26-latin-regular.svg#Arimo') format('svg'); /* Legacy iOS */
}
/* arimo-700 - latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/arimo-v26-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/arimo-v26-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/arimo-v26-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/arimo-v26-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/arimo-v26-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/arimo-v26-latin-700.svg#Arimo') format('svg'); /* Legacy iOS */
}

$fa-font-path: "../../assets/fonts" !default;