@import 'mixins';
@import 'fonts';
@import '../utils/font-awesome';

// This fixes author device emulator background overwrite problem.
#cq-emulator-content.firefox {
  background-color: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 15px 0;
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

.selection-bold {
  font-weight: 700;
}

input[type="search"] {
  box-sizing: content-box;
}

.component {

  &-content {
    @include clearfix;
  }

}

.clearfix {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}

.pull {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}

.only-mobile, .only-tablet, .only-desktop {
  display: none;
}

/* videos */

.jwplayer.jw-skin-site .jw-display-icon-container {
  border-style: solid;
  border-width: medium;
  border-radius: 50%;
  padding: 1em;
}

.jwplayer.jw-skin-alaska .jw-display-controls .jw-display-icon-container .jw-icon,
.jwplayer.jw-skin-alaska .jw-display-controls .jw-display-icon-container .jw-icon {
  color: $pink;
}

/* forms */

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* spacing */

.no-space {
  margin: 0;
  padding: 0;
}

.space-inside-top {
  padding-top: 30px;
}

.space-inside-left {
  padding-left: 30px;
}

.space-inside-right {
  padding-right: 30px;
}

.space-inside-bottom {
  padding-bottom: 30px;
}

/* alignments */

.middle {
  margin: 0 auto;
  text-align: center;
}

.right {
  margin: 0 0 0 auto;
  text-align: right;
}

.vertically-middle .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vertically-middle .content > * {
  display: inline-block;
}

/* borders */

.border-top {
  border-top: 1px solid #fdf3f6;
}

.border-left {
  border-left: 1px solid #fdf3f6;
}

.border-right {
  border-right: 1px solid #fdf3f6;
}

.border-bottom {
  border-bottom: 1px solid #fdf3f6;
}

.border-pink > div {
  border: 2px solid #f15783;
}

.border-blue > div {
  border: 2px solid #5da4e2;
}

/* misc */

.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

.paragraphSystem, .row {
  margin: 0;
}

/* main */

html, body {
  width: 100%;
  font-size: 1rem;
  background: $blue-lighter;
}

body {
  position: relative;
  overflow: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Arimo', sans-serif;
  font-weight: 400;
}

.container {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

body > .container > .row {
  position: relative;
}

/* layout: header */

div#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  width: 100%;
  max-width: 100%;
  padding: 0;
  border-bottom: 1px solid #dee2e6;
  overflow: visible;
  background: #fff;
  z-index: 9999;
}

div#header div[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.reference-header-public > .inner,
.reference-header-hcp > .inner {
  margin: 0 auto;
  max-width: 1140px;
}

.reference-header-public > .inner > .component-content,
.reference-header-hcp > .inner > .component-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.reference-header-public .header-sidebar > div,
.reference-header-hcp .header-sidebar > div {
  margin-left: auto;
}

.reference-header-public .image,
.reference-header-hcp .image {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header-public .richText,
.reference-header-hcp .richText {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header-public .header-logo,
.reference-header-hcp .header-logo{
  min-width: 200px;
}

.reference-header-public .header-logo-secondary,
.reference-header-hcp .header-logo-secondary {
    text-align: right;
}

.reference-header-public .header-logo > div,
.reference-header-public .header-logo-secondary > div,
.reference-header-hcp .header-logo > div,
.reference-header-hcp .header-logo-secondary > div {
  text-align: center;
}

.reference-header-public .header-logo a,
.reference-header-public .header-logo-secondary > div,
.reference-header-hcp .header-logo a,
.reference-header-hcp .header-logo-secondary > div {
  display: inline-block;
  height: $header-height;
  line-height: 62px;
  padding: 0 20px;
}

.reference-header-public .header-logo img,
.reference-header-public .header-logo-secondary img,
.reference-header-hcp .header-logo img,
.reference-header-hcp .header-logo-secondary img {
  display: inline-block;
  vertical-align: middle;
}

.reference-header-public .header-logo img,
.reference-header-hcp .header-logo img {
  height: 48px;
  max-height: 48px;
}

.reference-header-public .header-logo-secondary img,
.reference-header-hcp .header-logo-secondary img {
  height: 20px;
  max-height: 20px;
}

.reference-header-public .box-heading,
.reference-header-hcp .box-heading {
  display: none;
}

/* layout: content */

div#content {
  margin-top: $header-height;
  padding: 0;
  background: #fff;
}

div#content .layout-inner > div.col-xs-12, div#content .layout-inner > div.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

#content h1,
#content h2,
#content h3,
#content h4,
#content h5,
#content h6 {
  font-family: 'Merriweather Sans', sans-serif;
  color: #000;
}

#content h1 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: $pink;
}

#content h2 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: $pink;
}

#content h4 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: $pink;
}

#content h6 {
  margin: 0;
  padding: 15px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: $pink;
}

/* title border */

.headline-border h1,
.headline-border h2,
.headline-border h3,
.headline-border h4,
.headline-border h5,
.headline-border h6 {
  display: inline-block;
}

.headline-border h1.title-heading::before,
.headline-border h2.title-heading::before,
.headline-border h3.title-heading::before,
.headline-border h4.title-heading::before,
.headline-border h5.title-heading::before,
.headline-border h6.title-heading::before {
  display: block;
  margin: 0 0 8px 0;
  content: " ";
  height: 2px;
  max-width: 140px;
  background: $pink;
}

/* bg styles */

.bg-blue {
  background-color: $blue;
}

.bg-blue-dark {
  background-color: $blue-dark;
}

.bg-blue-light {
  background-color: $blue-light;
}

.bg-blue-lighter {
  background-color: $blue-lighter;
}

.bg-pink {
  background-color: $pink;
}

.bg-pink-light {
  background-color: $pink-light;
}

.bg-grey {
  background-color: $grey;
}

.bg-grey-dark {
  background-color: $grey-dark;
}

.bg-turquoise {
  background-color: $turquoise;
}

/* formatting */

.superscript {
  font-size: 70%;
  vertical-align: super;
}

/* divider */

.divider {
  display: block;
  width: 100%;
  padding: 15px 15px 0 15px;
  clear: both;
}

.divider > div {
  display: block;
  width: 100%;
  height: 15px;
  border-top: 1px solid #e5e5e5;
}

.divider.clearer > div {
  height: 0.01rem;
  clear: both;
  border: none;
}

.divider.spacer {
  display: block;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.divider.spacer > div {
  display: block;
  width: 100%;
  height: 20px;
  border-top: none;
}

/* approval code */

.approval-code {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ccc;
}

/* buttons */

.download-button {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 20px 10px 37px;
  color: #fff;
  background: #F15782;
  text-transform: uppercase;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

#content .richText a.download-button {
  color: #fff;
}

.download-button:before {
  position: absolute;
  content: " ";
  top: 11px;
  left: 15px;
  height: 15px;
  width: 15px;
  background: url(../../img/icons/arrow-down.svg) no-repeat;
  background-size: 15px 15px;
}


.download-button:link,
.download-button:visited {
  transition: all 0.2s;
}

.download-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.download-button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.download-button::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.download-button::after {
  background-color: #F15782;
}

.download-button:hover::after {
  transform: scaleX(1.2) scaleY(1.4);
  opacity: 0;
}

.back-button {
  position: relative;
  box-sizing: border-box;
  padding-left: 37px;
}

.back-button:before {
  position: absolute;
  content: " ";
  left: 3px;
  height: 24px;
  width: 24px;
  background: url(../../img/icons/play.svg) no-repeat;
  background-size: 24px 24px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-facebook {
  display: inline-block;
  overflow: hidden;
  height: 35px;
  width: 35px;
  background: url(../../img/icons/facebook.svg) no-repeat;
  background-size: 35px 35px;
  content: " ";
  text-indent: -9999px;
}

.icon-instagram {
  display: inline-block;
  overflow: hidden;
  height: 35px;
  width: 35px;
  background: url(../../img/icons/instagram.svg) no-repeat;
  background-size: 35px 35px;
  content: " ";
  text-indent: -9999px;
}

.icon-twitter {
  display: inline-block;
  overflow: hidden;
  height: 35px;
  width: 35px;
  background: url(../../img/icons/twitter.svg) no-repeat;
  background-size: 35px 35px;
  content: " ";
  text-indent: -9999px;
}

.icon-youtube {
  display: inline-block;
  overflow: hidden;
  height: 35px;
  width: 35px;
  background: url(../../img/icons/youtube.svg) no-repeat;
  background-size: 35px 35px;
  content: " ";
  text-indent: -9999px;
}

.lock-icon:before {
  @include font-awesome($fa-var-lock);
  padding-right: 10px;
}

.calendar-info:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
}

.time-info:before {
  @include font-awesome($fa-var-clock-o);
  padding-right: 10px;
}

.icon-pdf:before {
  @include font-awesome($fa-var-file-pdf-o);
  padding-right: 10px;
}

.icon-download:before {
  @include font-awesome($fa-var-download);
  padding-right: 10px;
}

.icon-back:before {
  @include font-awesome($fa-var-chevron-left);
  padding-right: 10px;
}

/* layout: content / title */

#content .title {
  margin: 0;
  padding: 0 20px;
}

#content .title:first-of-type {
  padding-top: 20px;
}

/* layout: content / image */

.image {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.image figcaption {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.image a {
  display: block;
  position: relative;
}

.image.link a:before, .image.download a:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  content: " ";
  height: 40px;
  width: 40px;
  background: #ff6600 url('../../assets/img/download-icon.svg') no-repeat center center;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-box-shadow: 0 0 8px 2px #ddd;
  -moz-box-shadow: 0 0 8px 2px #ddd;
  box-shadow: 0 0 8px 2px #ddd;
}

.image.link a:before {
  background: #ff6600 url('../../assets/img/next-arrow-icon.svg') no-repeat center center;
}

.image.link a:hover:before {
  left: 52%;
  transform: translate(-48%, -50%);
}

.image.download a:before {
  background: #ff6600 url('../../assets/img/download-icon.svg') no-repeat center center;
}

.image.download a:hover:before {
  top: 52%;
  transform: translate(-50%, -48%);
}

.image img {
  max-width: 100%;
}

/* layout: content / richText */

#content .richText p {
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0;
  padding: 10px 0;
}

#content .bg-pink .richText p,
#content .bg-blue .richText p,
#content .bg-blue-dark .richText p,
#content .bg-grey .richText p {
  color: #fff;
}

#content .richText a {
  font-size: 1rem;
  line-height: 1.6rem;
  color: $pink;
}

#content .bg-pink .richText a,
#content .bg-blue .richText a,
#content .bg-blue-dark .richText a,
#content .bg-grey .richText a {
  color: #fff;
  text-decoration: underline;
}

#content .richText ul {
  margin: 15px 0 15px 30px;
  padding: 0;
  list-style-type: none;
}

#content .richText ul li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 30px;
  font-size: 1rem;
  line-height: 1.6rem;
}

#content .richText ul li:before {
  @include font-awesome($fa-var-check);
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
}

#content .richText ol {
  margin: 15px 0 15px 30px;
  padding: 0;
}

#content .richText ol li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 15px;
  font-size: 1rem;
  line-height: 1.6rem;
}

/* layout: content / table */

.table-head-pink {
  background-color: $pink;
}

.table-head-pink h1,
.table-head-pink h2,
.table-head-pink h3,
.table-head-pink h4,
.table-head-pink h5,
.table-head-pink h6 {
  color: #fff !important;
}

.table-head-blue {
  background-color: $blue;
}

.table-head-blue h1,
.table-head-blue h2,
.table-head-blue h3,
.table-head-blue h4,
.table-head-blue h5,
.table-head-blue h6 {
  color: #fff !important;
}

#content .table-head:first-of-type {
  padding: 0 15px;
}

.table-image-teaser {
  margin: 0;
  padding: 0;
}

.table-image-teaser .image {
  margin: 0;
  padding: 0;
}

.table-image-teaser .richText {
  padding: 0 15px
}

.table-image-teaser-pink {
  background-color: $pink-light;
}

.table-image-teaser-blue {
  background-color: $blue-light;
}



#content .table .table-heading {
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.7rem;
}

#content .table {
  overflow-x: scroll;
}

#content .table table {
  width: 100%;
  min-width: 600px;
  margin: 15px 0;
  border-collapse: separate;
  border-spacing: 0;
}

#content .table table tbody {
  margin: 0;
  padding: 0;
}

#content .table table tr {
  background: #fff;
}

#content .table table tr:nth-child(odd) {
  background: #f6f6f6;
}

#content .table table tr:nth-child(odd) td {
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

#content .table table tr:first-child {
  background: #fff;
}

#content .table table td {
  margin: 0;
  padding: 10px 15px;
  vertical-align: top;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8rem;
}

#content .table table tr:first-child td {
  font-size: 1rem;
  font-weight: 700;
  color: #333;
  border-top: none;
  border-bottom: 1px solid #464646;
}

/* layout: content / header feature */

.header-feature {
  min-height: 300px;
  background: rgb(93,164,226);
  background: linear-gradient(90deg, rgba(93,164,226,1) 0%, rgba(222,240,255,1) 100%);
}

.header-feature:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 84px;
  content: " ";
  z-index: 999;
  background-image: url('../../img/feature-footer.svg');
  background-size: auto 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.header-feature > div {
  max-width: 1140px;
  margin: 0 auto;
}

.header-feature .image {
  margin: 15px 0 0 0;
  padding: 0;
  text-align: center;
}

.header-feature .image a {
  text-align: center;
}

.header-feature .richText {
  padding: 20px 15px 84px 15px;
}

.header-feature .richText h1 a {
  font-size: 2rem;
  line-height: 2.8rem;
  color: #f15783;
  font-weight: bold;
}

.header-feature .richText p a {
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: #000;
}

/* layout: content / image row */

.image-row-even, .image-row-odd {
  border-bottom: 2px solid #fff;
}

.image-row-even > div, .image-row-odd > div {
  padding: 10px 0;
}

.image-row-even > div > .content, .image-row-odd > div > .content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;
}

.image-row-even > div > div > .image,
.image-row-even > div > div > .richText,
.image-row-odd > div > div > .image,
.image-row-odd > div > div > .richText {

}

.image-row-even {
  background: $pink-light;
}

.image-row-odd {
  background: #fff;
}

/* layout: content / article teaser v2 */

#content .articleTeaserV2 {
  cursor: pointer;
}

/* layout: content / article teaser v2 / icon teaser */

#content .articleTeaserV2.icon-teaser .aspect-ratio-box {
  padding: 40px;
  text-align: center;
}

#content .articleTeaserV2.icon-teaser .aspect-ratio-box img {
  max-height: 100px;
}

#content .articleTeaserV2.icon-teaser.bg-blue-light {
  background-color: transparent;
}

#content .articleTeaserV2.icon-teaser.bg-blue-light .aspect-ratio-box {
  background-color: $blue-light;
}

#content .articleTeaserV2.icon-teaser.bg-blue-lighter {
  background-color: transparent;
}

#content .articleTeaserV2.icon-teaser.bg-blue-lighter .aspect-ratio-box {
  background-color: $blue-lighter;
}

/* layout: content / article teaser v2 / icon box teaser */

#content .articleTeaserV2.icon-box-teaser {
  padding: 15px;
  box-sizing: border-box;
}

#content .articleTeaserV2.icon-box-teaser > div {
  padding-right: 15px;
}

#content .articleTeaserV2.icon-box-teaser .article-title {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: $grey-dark;
}

#content .articleTeaserV2.icon-box-teaser .article-teaser-block {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  padding: 0 40px 0 0;
  background-image: url(../../img/icons/next.svg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

#content .articleTeaserV2.icon-box-teaser .aspect-ratio-box {
  flex: 0;
  padding: 30px;
}

#content .articleTeaserV2.icon-box-teaser .aspect-ratio-box img {
  max-width: 60px;
  max-height: 60px;
}

#content .articleTeaserV2.icon-box-teaser .article-teaser-content {
  flex: 1;
  padding: 15px;
  overflow: hidden;
  max-height: 100px;
}

#content .articleTeaserV2.icon-box-teaser .article-teaser-content .article-title p {
  margin: 0;
  padding: 0;
}

#content .articleTeaserV2.icon-box-teaser .article-button-container p.button-text {
  margin: 0;
  padding: 5px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#content .articleTeaserV2.icon-box-teaser.pink > div {
  border: 1px solid $pink;
}

#content .articleTeaserV2.icon-box-teaser.blue > div {
  border: 1px solid $blue;
}

/* layout: content / article teaser v2 / image teaser */

#content .articleTeaserV2.image-teaser {
  padding: 15px;
  box-sizing: border-box;
}

#content .articleTeaserV2.image-teaser .aspect-ratio-box {
  overflow: hidden;
}

#content .articleTeaserV2.image-teaser .aspect-ratio-box img {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .label {
  color: $grey-dark;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .label p {
  margin: 15px 0 0 0;
  font-weight: 400;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .article-title {
  font-weight: 700;
  color: $pink;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .article-title p {
  margin: 6px 0 0 0;
  font-size: 1.2rem;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .article-date {
  margin: 6px 0 0 0;
  font-weight: 700;
  color: $grey-dark;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .article-button-container {
  color: $grey-dark;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .article-button-container p {
  margin: 10px 0 0 0;
}

#content .articleTeaserV2.image-teaser .article-teaser-content .article-button-container p a {
  color: $grey-dark;
}

/* layout: content / article teaser v2 / image teaser left */

#content .articleTeaserV2.image-teaser-left {
  padding: 15px;
}

#content .articleTeaserV2.image-teaser-left .article-teaser-block {
  display: flex;
  justify-content: space-between;
}

#content .articleTeaserV2.image-teaser-left .article-teaser-block > div {
  flex-basis: 47%;
}

#content .articleTeaserV2.image-teaser-left .article-teaser-block p.margin-none-bottom {
  margin-top: 0;
}

#content .articleTeaserV2.image-teaser-left .article-button-container {
  display: none;
}

/* layout: content / teaser box */

#content .teaser-box {
  padding: 15px 15px;
  box-sizing: border-box;
  background: transparent;
}

#content .teaser-box.bg-blue > div,
#content .teaser-box.bg-blue-dark > div,
#content .teaser-box.bg-blue-light > div,
#content .teaser-box.bg-blue-lighter > div,
#content .teaser-box.bg-turquoise > div,
#content .teaser-box.bg-pink > div,
#content .teaser-box.bg-pink-light > div {
  padding: 15px;
}

#content .teaser-box.bg-blue > div {
  background: $blue;
}

#content .teaser-box.bg-blue-dark > div {
  background: $blue-dark;
}

#content .teaser-box.bg-blue-light > div {
  background: $blue-light;
}

#content .teaser-box.bg-blue-lighter > div {
  background: $blue-lighter;
}

#content .teaser-box.bg-turquoise > div {
  background: $turquoise;
}

#content .teaser-box.bg-pink > div {
  background: $pink;
}

#content .teaser-box.bg-pink-light > div {
  background: $pink-light;
}

#content .teaser-box.bg-grey > div {
  background: $grey;
}

#content .teaser-box .richText {
  box-sizing: border-box;
}

/* layout: content / legal note */

#content .legal-note {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #888;
}

/* layout: content / disclaimer */

#content .disclaimer {
  color: #333;
}

#content .disclaimer p, #content .disclaimer ul, #content .disclaimer ul li, #content .disclaimer ol, #content .disclaimer ol li {
  font-size: 0.9rem;
  line-height: 1.3rem;
}

/* layout: content / article compact */

#content .article-compact {
  clear: both;
  float: none;
  width: 100%;
}

#content .article-compact > div {
  float: none;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 1140px;
}

/* layout: content / block: video asset */

.video-asset {
  margin: 20px 0;
  padding: 0;
  background: #fff;
}

.video-asset-with-link .video-asset-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.video-asset-with-link .video-asset-content-body {
  width: 70%;
}

.video-asset-with-link .video-asset-content-link {
  width: 30%;
}

.video-asset-content {
  padding: 10px 0 10px 15px;
}

.video-asset-content-body {
  min-height: 110px;
  padding: 0 10px 0 0;
  overflow: hidden;
}

.video-asset-with-link .video-asset-content-body {
  border-right: 1px solid #c6c6c6;
}

.video-asset-content-body .video-asset-title p {
  margin: 5px 0;
  padding: 0;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.5rem;

}

.video-asset-content-body .video-asset-description p {
  margin: 5px 0;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
}

.video-asset-content-body .video-asset-approval-code {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #ccc;
}

.video-asset-content-link {
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

.videoAsset-video > video {
  width: 100%;
  max-width: 100%;
}

/* layout: content / search box */

.search-results .searchBox form {
  position: relative;
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  height: $header-height;
}

.search-results .searchBox .searchBox-title {
  display: none;
}

.search-results .searchBox form label {
  display: block;
  font-weight: 400;
}

.search-results .searchBox form input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 4px 40px 4px 3px;
  line-height: 2rem;
  border: 1px solid $pink;
  background: #fff;
  font-size: 1rem;
  text-indent: 12px;
}

.search-results .searchBox form button {
  position: absolute;
  top: 13px;
  right: 0;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
}

.search-results .searchBox form button {
  font-size: 0;
  color: transparent;
  cursor: pointer;
}

/* layout: search results / search box */

.search-results form {
  position: relative;
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  height: $header-height;
}

.search-results .searchBox-title {
  display: none;
}

.search-results .searchBox form input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 4px 40px 4px 3px;
  line-height: 2rem;
  border: 1px solid $pink;
  background: #fff;
  font-size: 1rem;
  text-indent: 12px;
}

.search-results .searchBox form button {
  position: absolute;
  top: 0px;
  right: 0;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
}

.search-results .searchBox form button,
.search-results .searchBox form button:hover,
#content .search-results .button {
  padding: 13px 15px;
  font-size: 0;
  color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
}

.search-results .searchBox form button:before {
  position: absolute;
  content: " ";
  background-image: url(../../img/icons/search.svg);
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
  left: 6px;
  display: inline-block;
  color: #000;
}

/* layout: content / bubble teaser */

.bubble-teaser {
  margin: 15px 0;
}

.bubble-teaser > div {
  min-height: 250px;
  margin: 0;
  padding: 20px 20px 40px 20px;
  background-color: #DEF0FF;
}

.bubble-teaser h2, #content .bubble-teaser h2 {
  margin: 15px 0 0 0;
  padding: 0;
  color: #000;
  font-size: 1.3rem;
}

/* layout: content / article teaser - teaser box */

.teaser-box {
  position: relative;
  margin: 15px 0;
}

.teaser-box .articleTeaser-text-description, .teaser-box .articleTeaser-text-description p {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #333;
}

#content .teaser-box h2.articleTeaser-text-header {
  padding: 10px 0;
}

.teaser-box .articleTeaser-text-webinar-date,
.teaser-box .articleTeaser-text-webinar-time {
  padding: 4px 0;
  font-weight: bold;
  color: #A1BB21;
}

.teaser-box .articleTeaser-text-webinar-date:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
  color: #A1BB21;
}

.teaser-box .articleTeaser-text-webinar-time:before {
  @include font-awesome($fa-var-clock-o);
  padding-right: 10px;
  color: #A1BB21;
}

.teaser-box .articleTeaser-cta {
  position: absolute;
  display: block;
  left: 10px;
  bottom: 20px;
  right: 10px;
  text-align: center;
}

.teaser-box .articleTeaser-cta .articleTeaser-text-link {
  display: inline-block;
}

.teaser-box .articleTeaser-cta .articleTeaser-text-link.button {
  margin: 0;
  padding: 12px 25px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

/* white boxed */

.teaser-box-style-white-boxed {
  height: 520px;
  background: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.teaser-box-style-white-boxed .articleTeaser-image-holder {
  width: 100%;
  box-sizing: border-box;
}

.teaser-box-style-white-boxed .articleTeaser-image-holder a {
  display: block;
  height: 160px;
  margin: 15px;
  padding: 15px;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-color: #E8EDF5;
  background-repeat: no-repeat;
}

.teaser-box-style-white-boxed .articleTeaser-image-holder a img {
  display: none;
}

.teaser-box-style-white-boxed .articleTeaser-text-content {
  height: 215px;
  padding: 0 15px 15px 15px;
  overflow-y: auto;
}

#content .teaser-box-style-white-boxed h2.articleTeaser-text-header,
#content .teaser-box-style-white-boxed h2.articleTeaser-text-header a {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  line-height: 1.7rem;
  font-weight: 700;
}

.teaser-box-style-white-boxed .articleTeaser-text-subline {
  color: #A1BB21;
}

.teaser-box-style-white-boxed .articleTeaser-cta {
  -webkit-box-shadow: 0px -6px 6px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px -6px 6px rgba(255, 255, 255, 1);
  box-shadow: 0px -6px 6px rgba(255, 255, 255, 1);
}

.teaser-box-style-white-boxed .articleTeaser-cta .articleTeaser-text-link.button {
  background: transparent;
  color: #45669d;
  border: 1px solid #d4dce9;
}

.teaser-box-style-white-boxed .articleTeaser-cta .articleTeaser-text-link.button:hover {
  background: #d4dce9;
  color: #3a4375;
}

/* blue boxed 1 */

.teaser-box-style-blue-boxed-1,
.teaser-box-style-blue-boxed-2,
.teaser-box-style-blue-boxed-3 {
  height: 440px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.teaser-box-style-blue-boxed-1 {
  background: #EDF1F6;
}

.teaser-box-style-blue-boxed-2 {
  background: #D7E0EC;
}

.teaser-box-style-blue-boxed-3 {
  background: #C2D0E1;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-main,
.teaser-box-style-blue-boxed-2 .articleTeaser-main,
.teaser-box-style-blue-boxed-3 .articleTeaser-main {
  height: 370px;
  overflow-x: scroll;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-image-holder,
.teaser-box-style-blue-boxed-2 .articleTeaser-image-holder,
.teaser-box-style-blue-boxed-3 .articleTeaser-image-holder {
  width: 100%;
  padding: 15px 15px 0 15px;
  box-sizing: border-box;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-image-holder a,
.teaser-box-style-blue-boxed-2 .articleTeaser-image-holder a,
.teaser-box-style-blue-boxed-3 .articleTeaser-image-holder a {
  display: block;
  padding: 15px;
  text-align: center;
  background-image: none !important;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-text-content,
.teaser-box-style-blue-boxed-2 .articleTeaser-text-content,
.teaser-box-style-blue-boxed-3 .articleTeaser-text-content {
  padding: 0 30px 0 30px;
}

#content .teaser-box-style-blue-boxed-1 h2.articleTeaser-text-header, #content .teaser-box-style-blue-boxed-1 h2.articleTeaser-text-header a,
#content .teaser-box-style-blue-boxed-2 h2.articleTeaser-text-header, #content .teaser-box-style-blue-boxed-2 h2.articleTeaser-text-header a,
#content .teaser-box-style-blue-boxed-3 h2.articleTeaser-text-header, #content .teaser-box-style-blue-boxed-3 h2.articleTeaser-text-header a {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 1.6em;
  font-weight: 700;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-text-subline,
.teaser-box-style-blue-boxed-2 .articleTeaser-text-subline,
.teaser-box-style-blue-boxed-3 .articleTeaser-text-subline {
  color: #fff;
  font-weight: 700;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-cta {
  -webkit-box-shadow: 0px -6px 6px #EDF1F6;
  -moz-box-shadow: 0px -6px 6px #EDF1F6;
  box-shadow: 0px -6px 6px #EDF1F6;
}

.teaser-box-style-blue-boxed-2 .articleTeaser-cta {
  -webkit-box-shadow: 0px -6px 6px #D7E0EC;
  -moz-box-shadow: 0px -6px 6px #D7E0EC;
  box-shadow: 0px -6px 6px #D7E0EC;
}

.teaser-box-style-blue-boxed-3 .articleTeaser-cta {
  -webkit-box-shadow: 0px -6px 6px #C2D0E1;
  -moz-box-shadow: 0px -6px 6px #C2D0E1;
  box-shadow: 0px -6px 6px #C2D0E1;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-cta .articleTeaser-text-link.button {
  border: 1px solid #F6F9FB;
  background: #F6F9FB;
}

.teaser-box-style-blue-boxed-2 .articleTeaser-cta .articleTeaser-text-link.button {
  border: 1px solid #EFF2F7;
  background: #EFF2F7;
}

.teaser-box-style-blue-boxed-3 .articleTeaser-cta .articleTeaser-text-link.button {
  border: 1px solid #E7ECF2;
  background: #E7ECF2;
}

.teaser-box-style-blue-boxed-1 .articleTeaser-cta .articleTeaser-text-link.button:hover,
.teaser-box-style-blue-boxed-2 .articleTeaser-cta .articleTeaser-text-link.button:hover,
.teaser-box-style-blue-boxed-3 .articleTeaser-cta .articleTeaser-text-link.button:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #3a4375;
}

/* layout: footer */

div#footer {
  margin: 15px 0 0 0;
  padding: 20px 0 0 0;
}

.reference-footer > .inner {
  margin: 0 auto;
  max-width: 1140px;
}

.reference-footer .component-content {
  text-align: center;
}

.reference-footer .image {
  margin: 0;
  padding: 10px;
}

.reference-footer .image img {
  width: 50px;
}

.reference-footer .richText ul {
  display: block;
  margin: 0;
  padding: 10px 0;
  list-style-type: none;
}

.reference-footer .richText ul li {
  display: block;
  margin: 0;
  padding: 10px 0;
}

.reference-footer .richText ul li a {
  font-size: 1rem;
  font-weight: 300;
  color: #000;
}

.reference-footer .richText ul li a:hover {
  text-decoration: underline;
}

.reference-footer .richText p {
  font-size: 1rem;
  font-weight: 300;
  color: #000;
}

.reference-footer .richText .small-light-grey {
  font-size: 0.85rem;
  font-weight: 300;
  color: $grey;
}

.reference-footer .richText.social-links  ul li {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

/* circles */

.circles-bottom-left-blue > div {
  background-image: url('../../assets/img/circles-sliced-left-blue@2.png');
  background-position: bottom left;
  background-size: 200px 74px;
  background-repeat: no-repeat;
}

.circles-bottom-left-pink > div {
  background-image: url('../../assets/img/circles-sliced-left-pink@2.png');
  background-position: bottom left;
  background-size: 200px 74px;
  background-repeat: no-repeat;
}

.circles-bottom-right-blue > div {
  background-image: url('../../assets/img/circles-sliced-right-blue@2.png');
  background-position: bottom right;
  background-size: 200px 97px;
  background-repeat: no-repeat;
}

.circles-bottom-right-pink > div {
  background-image: url('../../assets/img/circles-sliced-right-pink@2.png');
  background-position: bottom right;
  background-size: 200px 97px;
  background-repeat: no-repeat;
}

/* formatting */

.superscript {
  font-size: 70%;
  vertical-align: super;
}

/* divider */

.divider {
  @include align-center(medium);
  display: block;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.divider > div {
  display: block;
  width: 100%;
  height: 20px;
  border-top: 1px solid $blue-lighter;
}

.divider-pink {
  @include align-center(medium);
  display: block;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.divider-pink > div {
  display: block;
  width: 100%;
  height: 20px;
  border-top: 1px solid $pink;
}

/* icons */

.lock-icon:before {
  @include font-awesome($fa-var-lock);
  padding-right: 10px;
}

.calendar-info:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
}

.time-info:before {
  @include font-awesome($fa-var-clock-o);
  padding-right: 10px;
}

/* approval code */

.approval-code {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ccc;
}

/* boxes: video asset / jwplayer */

.reference-video-jwplayer {
  @include align-center(medium);
  padding: 0 20px;
}

.reference-video-jwplayer .videoAsset h3 {
  padding-bottom: 20px;
}

.reference-video-jwplayer .videoAsset p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin: 0;
  padding: 10px 0;
}

.reference-video-jwplayer .videoAsset-video {
  display: block;
  position: relative;
  min-height: 20px;
  background: #fefefe;
  margin: 0;
}

.reference-video-jwplayer .videoAsset-description {
  padding: 10px 0 0 0;
}

.reference-video-jwplayer .videoAsset span.videoAsset-duration {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.85rem;
  background: #000;
  color: #fff;
}

/* home */

.home-feature {
  margin: 0;
  padding: 0;
  background: #DEEFFF;
}

.home-feature .box {
  padding: 20px 15px;
}

.home-feature .title:first-of-type {
  padding-top: 0;
}

.home-feature .title h1 {
  margin: 10px 0;
  padding: 0;
}

.home-feature .title h3 {
  margin: 5px 0;
  padding: 0;
  color: #5da4e2 !important;
}

/* home feature */

.home-feature .carousel-slide {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 280px;
}

.header-feature h1, .home-feature p {
  color: #152F53;
}

.header-feature .searchBox {
  display: block;
  float: none;
  clear: both;
  max-width: 630px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-feature .searchBox form {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-feature .searchBox form:before {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 999;
  content: " ";
  height: 25px;
  width: 25px;
  background: url('../../assets/img/magnifier-blue.svg') no-repeat center center;
}

.header-feature .searchBox .searchBox-title {
  display: none;
}

.header-feature .searchBox form label {
  display: block;
  font-weight: 400;
}

.header-feature .searchBox form input {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  margin: 0;
  padding: 4px 140px 4px 3px;
  line-height: 2rem;
  border: 1px solid #fff;
  background: #fff;
  font-size: 1rem;
  text-indent: 60px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  font-family: 'Open Sans', sans-serif;
}

.header-feature .searchBox form input::-moz-placeholder,
.header-feature .searchBox form input::-webkit-input-placeholder,
.header-feature .searchBox form input:-ms-input-placeholder {
  font-style: italic;
  color: #ccc;
}

.header-feature .searchBox form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 45px;
  border: 1px solid #3A65A0;
  background: #3A65A0;
  color: #fff;
  padding: 0 30px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 700;
}

/* media queries */

@include respond-to(mobile) {

  /* display */

  .only-desktop {
    display: none;
  }

  .only-mobile {
    display: block !important;
  }

  .center-mobile {
    text-align: center;
  }

  /* spacing */

  .space-inside-top-mobile {
    padding-top: 30px;
  }

  .space-inside-left-mobile {
    padding-left: 30px;
  }

  .space-inside-right-mobile {
    padding-right: 30px;
  }

  .space-inside-bottom-mobile {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-mobile {
    border-top: 1px solid #c6c6c6;
  }

  .border-left-mobile {
    border-left: 1px solid #c6c6c6;
  }

  .border-right-mobile {
    border-right: 1px solid #c6c6c6;
  }

  .border-bottom-mobile {
    border-bottom: 1px solid #c6c6c6;
  }

  /* headlines */

  #content h1 {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  /* table image head */

  .table-head, #content .table-head:first-of-type {
    margin-top: 15px;
  }

  /* table image teaser */

  .table-image-teaser {
    margin-top: 15px;
  }

  /* container */

  .divider {
    @include align-center(medium);
    padding-top: 15px;
  }

  .divider > div {
    height: 15px;
  }

  /* footer */

  div#footer .footer-info {
    text-align: center;
  }

  div#footer .footer-links {
    text-align: center;
  }

  .reference-footer .userBox {
    display: none;
  }

  /* layout: content */

  .main .richText img {
    display: block;
    float: none;
    width: auto;
    margin: 40px auto;
  }

}

@include respond-to(tablet) {

  .container {
    right: 0;
    -webkit-transition: right .25s ease-out;
    -moz-transition: right .25s ease-out;
    -o-transition: right .25s ease-out;
    transition: right .25s ease-out;
  }

  .nav-is-open .container {
    display: block;
    right: -$navigation-drawer;
  }

  div#header {
    -webkit-transition: left .25s ease-out;
    -moz-transition: left .25s ease-out;
    -o-transition: left .25s ease-out;
    transition: left .25s ease-out;
  }

  #header .searchBox {
    display: block;
    width: 100%;
  }

  #header .searchBox form {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: $header-height;
  }

  #header .searchBox .searchBox-title {
    display: none;
  }

  #header .searchBox form label {
    display: block;
    font-weight: 400;
  }

  #header .searchBox form input {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    margin: 0;
    padding: 4px 40px 4px 3px;
    line-height: 2rem;
    border: 1px solid $pink;
    background: #fff;
    font-size: 1rem;
    text-indent: 12px;
  }

  #header .searchBox form button {
    position: absolute;
    top: 12px;
    right: 15px;
    height: 40px;
    width: 40px;
    background: none;
    border: 0;
  }

  #header .searchBox form button {
    color: transparent;
    font-size: 0;
    cursor: pointer;
  }

  #header .searchBox form button:before {
    position: absolute;
    content: " ";
    background-image: url(../../img/icons/search.svg);
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    top: 8px;
    left: 6px;
    font-weight: 900;
    font-size: 1.2rem;
    display: inline-block;
    color: #000;
  }

  .nav-is-open div#header {
    left: $navigation-drawer;
  }

  .reference-header-public > .inner > .component-content > div,
  .reference-header-hcp > .inner > .component-content > div {
    display: block;
  }

  .reference-header-public .mobile-menu,
  .reference-header-hcp .mobile-menu {
    display: flex;
    cursor: pointer;
    line-height: 62px;
    width: 80px;
    text-align: center;
  }

  .reference-header-public .mobile-menu > div.component-content,
  .reference-header-hcp .mobile-menu > div.component-content {
    display: inline-block;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .reference-header-public .mobile-menu > div.component-content > *,
  .reference-header-hcp .mobile-menu > div.component-content > * {
    display: none;
  }

  .reference-header-public .header-sidebar,
  .reference-header-hcp .header-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $navigation-drawer !important;
    min-height: 100vh;
    margin-left: -$navigation-drawer;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    background: #f2f2f2;
    -ms-overflow-style: none;
    overflow: auto;
  }

  .nav-is-open .reference-header-public .header-sidebar,
  .nav-is-open .reference-header-hcp .header-sidebar {
    margin-left: 0;
  }

  .reference-header-public .header-sidebar:after,
  .reference-header-hcp .header-sidebar:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    width: 10px;
    min-height: 100%;
    box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.2);
    content: "";
  }

  .reference-header-public .header-logo,
  .reference-header-hcp .header-logo {
    flex-grow: 1;
  }

  .reference-header-public .header-logo img,
  .reference-header-hcp .header-logo img {
    height: 100%;
  }

  /* display */

  .only-tablet {
    display: block !important;
  }

  /* spacing */

  .space-inside-top-tablet {
    padding-top: 30px;
  }

  .space-inside-left-tablet {
    padding-left: 30px;
  }

  .space-inside-right-tablet {
    padding-right: 30px;
  }

  .space-inside-bottom-tablet {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-tablet {
    border-top: 1px solid #c6c6c6;
  }

  .border-left-tablet {
    border-left: 1px solid #c6c6c6;
  }

  .border-right-tablet {
    border-right: 1px solid #c6c6c6;
  }

  .border-bottom-tablet {
    border-bottom: 1px solid #c6c6c6;
  }

  /* layout: content / article teaser v2 / image teaser left */

  #content .articleTeaserV2.image-teaser-left {
    padding: 0;
  }

  #content .articleTeaserV2.image-teaser-left .aspect-ratio-box img {
    height: 300px;
  }

  #content .articleTeaserV2.image-teaser-left .article-teaser-block {
    display: flex;
    justify-content: space-between;
  }

  #content .articleTeaserV2.image-teaser-left .article-teaser-block > div {
    flex-basis: 50%;
    padding: 15px;
  }

  #content .articleTeaserV2.image-teaser-left .article-button-container {
    display: block;
  }

  /* layout: footer */

  #footer .footer-links ul li {
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
  }

}

@include respond-to(desktop) {

  /* display */

  .only-desktop {
    display: block !important;
  }

  /* spacing */

  .space-inside-top-desktop {
    padding-top: 30px;
  }

  .space-inside-left-desktop {
    padding-left: 30px;
  }

  .space-inside-right-desktop {
    padding-right: 30px;
  }

  .space-inside-bottom-desktop {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-desktop {
    border-top: 1px solid #c6c6c6;
  }

  .border-left-desktop {
    border-left: 1px solid #c6c6c6;
  }

  .border-right-desktop {
    border-right: 1px solid #c6c6c6;
  }

  .border-bottom-desktop {
    border-bottom: 1px solid #c6c6c6;
  }

  /* mobile menu */

  .mobile-menu {
    display: none;
  }

  .reference-header-public .header-sidebar,
  .reference-header-hcp .header-sidebar {
    flex-grow: 1;
  }

  .reference-header-public .header-sidebar > div,
  .reference-header-hcp .header-sidebar > div {
    float: right;
  }

  #header .searchBox {
    display: none;
  }

  /* divider */

  .divider {
    @include desktop-padding();
  }

  /* layout: content */

  .main .title {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .title:first-of-type {
    padding-top: 30px;
  }

  .main .image {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .richText {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .table {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .searchBox {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .searchResults {
    @include align-center(medium);
    @include desktop-padding();
  }

  /* layout: footer */

  .reference-footer .component-content {
    text-align: left;
  }

  .reference-footer .richText ul li {
    display: inline-block;
    padding-right: 15px;
  }

  /* home */

  .home-feature .searchBox {
    display: block;
    float: none;
    clear: both;
    max-width: 630px;
    margin-left: 0;
    margin-right: 0;
    padding: 0 15px;
  }

  /* layout: content / article teaser v2 / image teaser left */

  #content .articleTeaserV2.image-teaser-left {
    padding: 0;
  }

  #content .articleTeaserV2.image-teaser-left .aspect-ratio-box img {
    height: 350px;
  }

  #content .articleTeaserV2.image-teaser-left .article-teaser-block {
    display: flex;
    justify-content: space-between;
  }

  #content .articleTeaserV2.image-teaser-left .article-teaser-block > div {
    flex-basis: 50%;
    padding: 15px;
  }

  #content .articleTeaserV2.image-teaser-left .article-button-container {
    display: block;
  }

  /* snippets: page image */

  .reference-page-image {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .reference-page-image .image {
    padding-left: 0;
    padding-right: 0;
  }

}

@include respond-to(large-desktop) {

  /* home */


}
