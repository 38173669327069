@import '../core/mixins';

.accordion-container {
  padding: 15px 0;
  margin: 0;
  list-style: none;
}

.accordion-slide {
  border-top: 1px solid $pink;
}

.accordion.sources .accordion-slide {
  border: none;
}

.accordion-slide:last-child {
  border-bottom: 1px solid $pink;
}

.accordion.sources .accordion-slide:last-child {
  border: none;
}

.accordion-head {
  display: block;
  padding: 15px 0;
  color: $grey-dark;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  background: transparent;
}

.accordion-title {
  float: none;
  margin: 0;
  padding: 0 60px 0 0;
  display: inline-block;
}

.accordion.sources .accordion-title {
  padding: 0;
}

.accordion-title-link {
  position: relative;
  display: inline-block;
  color: $grey-dark;
  vertical-align: middle;
  min-height: 30px;
}

.image-accordion .accordion-title-image {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 34px;
  max-height: 34px;
}

.image-accordion .accordion-title-text {
  display: inline-block;
  padding: 5px 0 0 50px;
}

.accordion-content {
  padding: 13px 0;
}

.accordion-content .richText {
  padding: 0;
}

.accordion-content-wrapper {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.mod-js .accordion-content-wrapper {
  position: relative;
  height: 0;
  top: -999999px;
  left: -999999px;
  overflow: hidden;
  box-sizing: border-box;
  visibility: hidden;
}

.mod-js .accordion-head {
  position: relative;
  cursor: pointer;
}

.mod-js .accordion-head:after {
  position: absolute;
  display: inline-block;
  top: 15px;
  right: 15px;
  height: 24px;
  width: 24px;
  background: url(../../img/icons/play.svg) no-repeat;
  background-size: 24px 24px;
  content: " ";
}

.mod-js .accordion.sources .accordion-head:after {
  position: relative;
  display: inline-block;
  content: "\f054";
  padding: 0 5px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  background: none;
  top: auto;
  right: auto;
  height: auto;
  width: auto;
  color: $grey-dark;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover,
.mod-js .accordion-slide.is-active > .accordion-head {
  color: #3A65A0;
  cursor: pointer;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover:after,
.mod-js .accordion-slide.is-active > .accordion-head:after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mod-js .accordion-slide .accordion-head:hover {
  color: #3A65A0;
}

.mod-js .accordion-slide.is-active > .accordion-head {
  color: black;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover {
  background-color: transparent;
}

.mod-js .accordion-slide.is-active > .accordion-content-wrapper {
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  visibility: visible;
}

.mod-js .accordion-slide.is-active:last-child > .accordion-content-wrapper {
  border-bottom: none;
}
